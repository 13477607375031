import React from "react";
import { FiActivity, FiCast, FiRefreshCcw } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    icon: <FiActivity />,
    title: "Learning That Works With You",
    description:
      "Our content reaches anywhere you have a computer, tablet or phone, and is quick enough to fit into any schedule.",
  },
  {
    icon: <FiRefreshCcw />,
    title: "Winning Content",
    description:
      "Short, video-based content is interesting and easy to understand. Brief knowledge checks after each unit help you know where to focus and review",
  },
  {
    icon: <FiCast />,
    title: "Learn at Your Pace",
    description:
      "Review any of your content whenever, and where ever you want. Watch a video before taking on a new task, or revisiting an old one.",
  },
];

const ServiceGrantStudent = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-4 col-sm-4 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="icon">{val.icon}</div>
              <div className="content">
                <h4 className="title w-600">
                  <Link
                    to="#service"
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h4>
                <p
                  className="description b1 color-gray mb--0"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceGrantStudent;
