import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import PageScrollTop from "./components/pageToTop/PageScrollTop";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

// Pages import Here
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContentCreation from "./pages/ContentCreation";
import Products from "./pages/Products";
import GettingStarted from "./pages/GettingStarted";
//Legeal
import Error from "./pages/Error";
import SignUp from "./pages/SignUp";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Cookie from "./pages/Cookie";

// Products
import WorkforceBasics from "./pages/products/WFB";
import HealthyCookingBasics from "./pages/products/HCB";
import JobofGettingaJob from "./pages/products/JGJ";
// Contact
import Contact from "./elements/contact/Contact";
import Subscribe from "./elements/contact/Subscribe";
import Content from "./elements/contact/Content";
// Blog Elements
import BlogDetails from "./pages/BlogDetails";
import HowToDetails from "./pages/HowToDetails";
import PortfolioItem from "./elements/portfolio/PortfolioItem";
import BlogGridView from "./components/blog/BlogGridView";
import BlogListView from "./components/blog/BlogListView";
import BlogGridSidebar from "./components/blog/BlogGridSidebar";
import BlogListSidebar from "./components/blog/BlogListSidebar";
import Tracker from "./components/Tracker";
// Grant
import Grant from "./pages/Grant";
import Agency from "./pages/grant/Agency";
import Student from "./pages/grant/Student";
import WFB from "./pages/grant/WFB";
import HCB from "./pages/grant/HCB";
import Study from "./pages/grant/Study";
// Classes
import Classes from "./pages/classes/Classes";
import ClassDetails from "./pages/classes/ClassDetails";
// Partners
import Abilitytech from "./pages/partners/412abilitytech";
// Events
import TestPage from "./pages/events/Test";

// Import Css Here
import "./assets/scss/style.scss";

const tagManagerArgs = {
  gtmId: "GTM-N3N55NC",
};
TagManager.initialize(tagManagerArgs);
ReactGA.initialize("G-QT18L48KVQ");
const App = () => {
  return (
    <HashRouter>
      <Tracker />
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + "/"}`}
            exact
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/grant"}`}
            exact
            component={Grant}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/grant/grantpartners"}`}
            exact
            component={Agency}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/grant/agency"}`}
            exact
            component={Agency}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/grant/student"}`}
            exact
            component={Student}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/grant/workforce"}`}
            exact
            component={WFB}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/grant/healthycooking"}`}
            exact
            component={HCB}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/grant/classes"}`}
            exact
            component={Classes}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/grant/study"}`}
            exact
            component={Study}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            exact
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/products"}`}
            exact
            component={Products}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/trial"}`}
            exact
            component={Products}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/subscribe"}`}
            exact
            component={Subscribe}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/about-us"}`}
            exact
            component={AboutUs}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/event"}`}
            exact
            component={Products}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/signup"}`}
            exact
            component={SignUp}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/products/jobofgettingajob"}`}
            exact
            component={JobofGettingaJob}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/ahnsignup"}`}
            exact
            component={Products}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/AhnTrail"}`}
            exact
            component={Products}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/portfolio-details"}`}
            exact
            component={PortfolioItem}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/content-creation"}`}
            exact
            component={ContentCreation}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/content"}`}
            exact
            component={Content}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/terms"}`}
            exact
            component={Terms}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/privacy"}`}
            exact
            component={Privacy}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/cookie"}`}
            exact
            component={Cookie}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-grid"}`}
            exact
            component={BlogGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-list-view"}`}
            exact
            component={BlogListView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-grid-sidebar"}`}
            exact
            component={BlogGridSidebar}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-list-sidebar"}`}
            exact
            component={BlogListSidebar}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
            exact
            component={BlogDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/test"}`}
            exact
            component={TestPage}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/classes/class-details/:id"}`}
            exact
            component={ClassDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/products/healtycooking"}`}
            exact
            component={HealthyCookingBasics}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/products/workforcebasics"}`}
            exact
            component={WorkforceBasics}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/gettingstarted"}`}
            exact
            component={GettingStarted}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/getting-started/:id"}`}
            exact
            component={HowToDetails}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/classes"}`}
            exact
            component={Classes}
          />
          <Route
            path={`${process.env.PUBLIC_URL + "/partners/abilitytech"}`}
            exact
            component={Abilitytech}
          />
          <Route component={Error} />
        </Switch>
      </PageScrollTop>
    </HashRouter>
  );
};

export default App;
