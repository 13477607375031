import { useState } from "react";
import { FiX, FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";

const HeaderTopNews = () => {
  const [deactive, setClass] = useState("");
  return (
    <div
      className={`header-top-news bg_image ${deactive}`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/top-banner.png)`,
      }}
    >
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <div className="content">
                  <span className="rn-badge">Free Access</span>
                  <span className="news-text">
                    We Just Won TWO NIDILRR and SBIR Grants and We Are Looking
                    for Partners{" "}
                  </span>
                </div>
                <div className="right-button">
                  <Link className="btn-read-more" to="/grant">
                    <span>
                      More Details <FiArrowRight />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="icon-close">
        <button className="close-button" onClick={() => setClass("deactive")}>
          <FiX />
        </button>
      </div>
    </div>
  );
};

export default HeaderTopNews;
